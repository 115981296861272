.dialog-paper {
  @apply bg-slate !important;
}

.dialog-content-root {
  @apply border;
  @apply border-slate;
  @apply bg-white;
  border-radius: 3px;
}
