@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full;
}

body {
  @apply font-roboto;
  @apply text-base;
}

h1 {
  @apply text-2xl;
  @apply font-medium;
}

/* Remove arrows from input[type="number"] */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
